body {
  background-color: #813a9d;
  color: white;
}

.App {
  /* text-align: center; */
  font-size: 24;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a{
  color:white;
  font-size: 24;
}

.section-title-hr {
  width: 100%;
  height: 20px;
  background-color: #934AB0;
  margin: 0;
  margin-bottom: 35px;
}

/* SWAL */
.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.right-gap {
  margin-right: auto;
}
.swal-wide{
    width:100vw !important;
    height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.map-container{
  width: 100vw;
  height: 100vh;
}

/* .logo {
  font-size: 200;
} */
h1 {
    display: inline-block;
    margin-right: 0.2em;
    font-size: 4em;
}

/* #email-address,#password {
  border-radius: 0;
} */

.App-header {
  background-color: #813a9d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fff;
}

.btn {
  width: 100%;
  border-radius: 0;
}

.home {
  min-height: 95vh;
}

.content {
  background-color: white;
  color: black;
  min-height: 100vh;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 80%;
    height: 60px;
    line-height: 60px;
    /* background-color: #f5f5f5; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
